import React, {useEffect, useRef, useState} from 'react';
import './BackgroundSlider.css';
import imageSlide from './SliderData';
import {IoPause} from "react-icons/io5";

const BackgroundSlider = () => {
    const [currentState, setCurrentState] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const intervalRef = useRef();

    useEffect(() => {
        const preloadAllImages = imageSlide.map(slide => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = slide.url;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(preloadAllImages)
            .then(() => {
                // All images are loaded, start the slideshow
                const nextSlide = () => setCurrentState(prevState => (prevState + 1) % imageSlide.length);
                const startSliding = () => {
                    setIsPaused(false);
                    intervalRef.current = setInterval(nextSlide, 5000);
                };
                startSliding();
            })
            .catch((error) => {
                // Handle error here
                console.log("Error loading images: ", error);
            });

        return () => {
            setIsPaused(true);
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
        };
    }, []);


    useEffect(() => {
        const container = document.querySelector('.description');

        const stopSliding = () => {
            setIsPaused(true);
            clearInterval(intervalRef.current);
        };

        const startSliding = () => {
            setIsPaused(false);
            intervalRef.current = setInterval(() => setCurrentState(prevState => (prevState + 1) % imageSlide.length), 5000);
        };

        container.addEventListener('mouseenter', stopSliding);
        container.addEventListener('mouseleave', startSliding);

        return () => {
            container.removeEventListener('mouseenter', stopSliding);
            container.removeEventListener('mouseleave', startSliding);
        };
    }, []);

    const bgImageStyle = {
        backgroundImage: `url(${imageSlide[currentState].url})`,
    };

    const styles = {
        bold: {
                fontWeight: 'bold',
        },
        body: {
            fontSize: '20px',
            color: 'white',
            textShadow: '2px 2px 4px #000000',
            marginBottom: '20px',
            textAlign: 'center',
        },
        back: {
            background: 'rgba(0, 0, 0, 0.8)',
            padding: '20px',
            borderRadius: '10px',
            margin : '0 20%'
        },
        link: {
            color: 'blue',
            textDecoration: 'underline',
        },
        copyable: {
            userSelect: 'all',
        },
    }
    return (
        <div id={'main'} className='container-style'>
            <div style={bgImageStyle} className={'imageSlider'}></div>
            <div className='description'>
                <div>
                    <h1 className="heroic-text">{imageSlide[currentState].title}</h1>
                    {/* <p>
                        {imageSlide[currentState].body.split('\n').map((paragraph, index) => (
                            <span key={index}><br/> {paragraph}</span>
                        ))}
                    </p> */}

                <p style={ {...styles.body, ...styles.bold} } >SURF IS NOT DEAD ON CS2! 🎉</p>
                <p style={styles.body}>
                We are proud to announce that we are now offering Surf servers for CS2!<br/>
                The server is now available and ready to be played on.<br/>
                We are looking forward to see you on the server and we hope you will enjoy it.</p>

                {/* Style of a dark background */}
                <div style={styles.back}>
                    <p style={styles.body}>🌊 <a rel="noreferrer" target="_blank" href='steam://connect/195.201.241.248:27015'>CS2 surf</a> <span style={styles.copyable}>connect frozen-wave.com:27015</span></p>
                    <p style={styles.body}>🌊 <a rel="noreferrer" target="_blank" href='https://discord.gg/uddHXF2gWN'>Discord</a> <span style={styles.copyable}>discord.gg/uddHXF2gWN</span></p>
                </div>
                </div>
                <div className='carousel-bullets'>
                    {imageSlide.map((_, index) => (
                        <span key={index} className={currentState === index ? 'active' : ''}
                              onClick={() => setCurrentState(index)}></span>
                    ))}
                </div>
                {isPaused && <IoPause className="pause-icon"/>}
            </div>
        </div>
    );
};

export default BackgroundSlider;
