const imageSlide = [
    {
        url: 'background_CSGO3.jpg',
        title: 'CS2 surf is now available!',
        body: '',
    },
    {
        url: 'background_CSGO1.jpg',
        title: 'Discover the CS2 Surf experience!',
        body: '',
    },
    {
        url: 'background_CSGO2.jpg',
        title: 'Come chill with us on CS2!',
        body: '',
    }
];

export default imageSlide;
