import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCopy, faSignInAlt} from '@fortawesome/free-solid-svg-icons';

function Server(props) {
    const handleCopyClick = () => {
        navigator.clipboard.writeText(`connect ${props.ip}`)
            .then(() => console.log('IP address copied to clipboard!'))
            .catch(err => console.error('Failed to copy text: ', err));
    };

    return (
        <div className='server'>
            <p className="highlight server-elm server-name">{props.name}</p>
            <div className="server-details">
                <p className="server-elm ip">{props.ip}</p>
            </div>
            <button className="server-elm copy-button" onClick={handleCopyClick}>
                <FontAwesomeIcon icon={faCopy}/>
            </button>
            <a className="server-elm server-join" href={"steam://connect/" + props.steam_ip}>
                <FontAwesomeIcon icon={faSignInAlt}/>
            </a>
        </div>
    );
}

export default Server;
