import React from "react";
import "./Subscribe.css";
import Logo from "../../images/frozenWave_v5.png";

function Subscribe() {
  return (
    <div id="subscribe">
      <div className="footer-container">
        <img src={Logo} class="fw-logo" alt="FrozenWave logo" />
        <span className="footer-span">Developed by Dam's and ~KittyJinxy || ©Copyright 2023 FrozenWave. All rights reserved.</span>
      </div>
    </div>
  );
}

export default Subscribe;
