import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "./Navbar.css";

function Navbar() {
  const [activeLink, setActiveLink] = useState("main");
  const [color, setColor] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const links = [
    { text: "Frozenwave", target: "main" },
    { text: "About Us", target: "features" },
    { text: "Servers", target: "servers" },
    { text: "Discord", target: "discord" },
    { text: "Donate", target: "donate" },
  ];

  const changeColor = () => {
    if (window.scrollY >= 80) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  const handleScroll = () => {
    const sections = links.map(link => document.getElementById(link.target));
    const scrolled = window.scrollY + window.innerHeight * 0.5; // modify this as you want
    const activeSection = sections.reduce((current, section) =>
        section.offsetTop <= scrolled && section.offsetTop + section.offsetHeight > scrolled
            ? section
            : current
    );
    setActiveLink(activeSection.id);
  }

  useEffect(() => {
    window.addEventListener("scroll", changeColor);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", changeColor);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleMenu = () => {
    if (isOpen) {
      setIsOpen(false);
      setActiveLink("");
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!document.querySelector(".menu-container").contains(e.target)) {
        setIsOpen(false);
      }
    };
    if (isOpen) {
      window.addEventListener("click", handleClickOutside);
    }
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen]);

  return (
      <div className={color ? "nav nav-bg" : "nav"}>
        <nav>
          <div className="menu-container">
            <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
              <div></div>
              <div></div>
              <div></div>
            </div>
            <ul className={isOpen ? "menu open" : "menu"}>
              {links.map((link) => (
                  <li key={link.target} className="nav-item">
                    <Link
                        to={link.target}
                        className={`nav-elm ${activeLink === link.target ? "active" : ""}`}
                        onClick={() => {
                          toggleMenu();
                          setActiveLink(link.target);
                        }}
                        smooth={true} // smooth scroll
                        duration={500} // scroll duration
                    >
                      {link.text}
                    </Link>
                  </li>
              ))}
            </ul>
          </div>
        </nav>
      </div>
  );
}

export default Navbar;
