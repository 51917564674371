import React from "react";
import discord from "../../images/Discord.png";
import VideoBackground from "./VideoBackground";
import "./Discord.css";

function Discord() {
    return (
        <div id="discord" style={{position: "relative"}}>
            <VideoBackground/>
            <div className="s-heading discord-title">
                <h1>Discord</h1>
            </div>
            <div className="discord-content">
                <div className="discord-text">
                    <h2 className="title">Advantages of Discord</h2>
                    <h3>Become a real member of FrozenWave by joining us !</h3>
                    <p>
                        By joining our Discord server, you will have access to
                        <span className={"highlight"}> many advantages such as:</span>
                    </p>
                    <ul>
                        <li>
                            Access to the <span className={"highlight"}>latest news</span>{" "}
                            about the servers
                        </li>
                        <li>
                            Stay informed about{" "}
                            <span className={"highlight"}>the records</span> of the servers
                        </li>
                        <li>
                            <span className={"highlight"}>Speak and chat</span> with other
                            members of the community
                        </li>
                        <li>Report bugs and get help from the community</li>
                        <li>And much more...</li>
                    </ul>

                    <p>
                        You will also be able to{" "}
                        <span className={"highlight"}>help us improving the community</span>{" "}
                        by suggesting new features and ideas, and by giving us your
                        feedbacks.
                    </p>

                    <p>
                        {" "}
                        The server is currently in Beta version, so{" "}
                        <span className={"highlight"}>we need you to improve it.</span>
                    </p>

                    <p>
                        <span className={"highlight"}>Join us now !</span>
                    </p>

                    <a
                        href="https://discord.gg/uddHXF2gWN"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cv-btn"
                    >
                        Join
                    </a>
                </div>

                <div className="discord-img">
                    <img src={discord} alt="discord"/>
                </div>
            </div>
        </div>
    );
}

export default Discord;
