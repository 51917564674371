import React from 'react';
import video from '../../images/background_video.mp4';

const VideoBackground = () => {
    return (
        <div className="video-container">
            <video autoPlay muted loop playsInline>
                <source src={video} type="video/mp4" />
            </video>
            <div className="video-overlay">
            </div>
            <p className="surfer credit">Surfed by Bidenureserkilozeramobonide</p>
        </div>
    );
};

export default VideoBackground;
