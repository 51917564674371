import React, { useState } from "react";
import "./Donate.css";

const denominations = [
  { value: 5, label: "5€" },
  { value: 10, label: "10€" },
  { value: 20, label: "20€" },
  { value: 30, label: "30€" },
  { value: 50, label: "50€" },
  { value: 100, label: "100€" },
];

function Donate() {
  const [selectedDenomination, setSelectedDenomination] = useState(20);
  const [otherAmount, setOtherAmount] = useState("");

  const handleDenominationClick = (value) => {
    setSelectedDenomination(value);
    setOtherAmount("");
  };
  const handleOtherAmountChange = (event) => {
    const value = event.target.value;
    setSelectedDenomination(value ? parseInt(value) : 20);
    setOtherAmount(value);
  };
  const handleOtherAmountKeyPress = (event) => {
    const key = event.target.value + event.key;
    const regex = /^\d+$/;
    if (!regex.test(key)) {
      event.preventDefault();
    }
  };

  const generatePayPalLink = (amount) => {
    return `https://www.paypal.com/paypalme/damscp/${amount}EUR`;
  };

  const handleDonateButtonClick = () => {
    const amount = otherAmount ? parseInt(otherAmount) : selectedDenomination;
    const paypalLink = generatePayPalLink(amount);
    window.open(paypalLink);
  };

  return (
    <div id="donate" className="donate-container">
      <div className="s-heading">
      <h1>Donate</h1>
      </div>
      <div className="donate">
        <div className="donate-black">
          <p>
            We rely on the support of our community to keep <strong>FrozenWave </strong>running
            smoothly and to bring you more exciting features in the future. </p>
            <p>If
            you enjoy playing on our servers and would like to contribute, we
            gratefully accept donations. Your generosity helps us maintain and
            expand our offerings, ensuring an exceptional gaming experience for
            all.
            </p>
            <p>
            <strong>Thank you for your support!</strong>
            </p>
        </div>
        <div className="donate-blue">
          <h3>Donation amount*</h3>
          <div className="donate-amount-box">
            <div className="donate-amount">
              {denominations.map((denomination) => (
                <div
                  key={denomination.value}
                  className={`denomination ${
                    selectedDenomination === denomination.value
                      ? "selected"
                      : ""
                  }`}
                  onClick={() => handleDenominationClick(denomination.value)}
                >
                  <input
                    type="radio"
                    name="amount"
                    id={`amount${denomination.value}`}
                    value={denomination.value}
                    checked={selectedDenomination === denomination.value}
                    onChange={() => handleDenominationClick(denomination.value)}
                  />
                  <label htmlFor={`amount${denomination.value}`}>
                    {denomination.label}
                  </label>
                </div>
              ))}
            </div>
            <div className="denomination-other">
              <input
                type="text"
                name="amount"
                value={otherAmount}
                placeholder="Enter Other Amount"
                onChange={handleOtherAmountChange}
                onKeyPress={handleOtherAmountKeyPress}
              />
            </div>
          </div>
        </div>
        <div className="donate-blue donate-payment">
          <h3>Payment Method: Paypal</h3>
          <div className="donate-submit">
            <button type="submit" onClick={handleDonateButtonClick} style={{margin: "4px auto"}}>
              Donate{" "}
              {otherAmount ? `${otherAmount}€` : `${selectedDenomination}€`}
            </button>
            <p>
              <small>
                Please note that contributions are not tax-deductible.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Donate;
