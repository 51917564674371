import React from "react";
import Server from "./Server";
import "./Servers.css";

function ServerList() {

    class FwServer {
        constructor(ip, port, name) {
            this.ip = ip;
            this.port = port;
            this.name = name
        }
    }


    const ip = '195.201.241.248'
    const servers = {
        "85.3-tick": [
            new FwServer("frozen-wave.com", "27001", "FrozenWave | Surf #1 | 85-tick | Beginner"),
            new FwServer("frozen-wave.com", "27002", "FrozenWave | Surf #2 | 85-tick | Easy"),
            new FwServer("frozen-wave.com", "27003", "FrozenWave | Surf #3 | 85-tick | Challenging"),
            new FwServer("frozen-wave.com", "27004", "FrozenWave | Surf #4 | 85-tick | Hard"),
        ],
        "64-tick": [
            new FwServer("frozen-wave.com", "27011", "FrozenWave | Surf #5 | 64-tick | Easy"),
            new FwServer("frozen-wave.com", "27012", "FrozenWave | Surf #6 | 64-tick | Challenging"),
            new FwServer("frozen-wave.com", "27013", "FrozenWave | Surf #7 | 64-tick | Hardcore"),
        ],
        "102.4-tick": [
            new FwServer("frozen-wave.com", "27021", "FrozenWave | Surf #8 | 102-tick | Easy"),
            new FwServer("frozen-wave.com", "27022", "FrozenWave | Surf #9 | 102-tick | Challenging"),
            new FwServer("frozen-wave.com", "27023", "FrozenWave | Surf #10 | 102-tick | Hard"),
        ]
    }
    const cs2SurfServers = [
        new FwServer("frozen-wave.com", "27015", "FrozenWave | CS2 Surf #1 | Beginner"),
    ]
    return (
        <div id="servers">
            <div className="s-heading">
                <h1>Servers</h1>
            {/*    <br/>*/}
            {/*    <p>*/}
            {/*        Here is the list of the servers we provide, more will be added in the*/}
            {/*        future!*/}
            {/*    </p>*/}
            {/*    <br/>*/}
            {/*    <p>*/}
            {/*        "Ticks" refer to the server's update rate. In our Surf servers, we have 64, 85, and 102.4-tick*/}
            {/*        rates.*/}
            {/*    </p>*/}
            {/*    <p>Higher ticks mean smoother gameplay, making surfing easier.</p>*/}
            {/*    <p>*/}
            {/*        Choose 102.4-tick for a fluid experience, 85-tick for a balanced challenge,*/}
            {/*        or 64-tick if you prefer a more traditional surf challenge.*/}
            {/*    </p>*/}
            {/*    <br/>*/}
            {/*    <p>*/}
            {/*        Enjoy the waves!*/}
            {/*    </p>*/}
            {/*    <p className="join-msg">*/}
            {/*        <br/>*/}
            {/*        You can join a server by clicking on the connect or using the copy button and pasting*/}
            {/*        the IP address directly in the CSGO's console.*/}
            {/*    </p>*/}
            </div>
            <div className="b-container server-list">
                <h2>CS2 Surf</h2>
                {cs2SurfServers.map((server) => (
                    <>
                        <h3>64-ticks + sub-ticks</h3>
                        <Server
                            key={`${server.ip}:${server.port}`}
                            name={server.name}
                            ticks="64-ticks + sub-ticks"
                            ip={`${server.ip}:${server.port}`}
                            steam_ip={`${ip}:${server.port}`}
                        />
                    </>
                ))}
                <h2>CS:GO Surf</h2>
                {Object.keys(servers).map((tick) => (
                    <div key={tick}>
                        <h3>{tick}</h3>
                        {servers[tick].map((server) => (
                            <Server
                                key={`${server.ip}:${server.port}`}
                                name={server.name}
                                ticks={tick.split("-")[0]}
                                ip={`${server.ip}:${server.port}`}
                                steam_ip={`${ip}:${server.port}`}
                            />
                        ))}
                    </div>
                ))}
            </div>

            <br/>
            <div className="s-heading">
                <p>Feel free to join our discord to get the latest updates!</p><br/>
            </div>
        </div>
    );
}

export default ServerList;
