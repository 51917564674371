import React from 'react';
import Aboutus from './components/About/Aboutus';
import Donate from './components/Donate/Donate';
import ServerList from './components/Servers/ServerList';
import Navbar from './components/Layout/Navbar/Navbar';
import './components/Home/BackgroundSlider/BackgroundSlider.css';
import Discord from "./components/Discord/Discord";
import BackgroundSlider from "./components/Home/BackgroundSlider/BackgroundSlider";
import Subscribe from "./components/Footer/Subscribe";

function App() {
    return (
        <div className="App">
            <Navbar/>
            <BackgroundSlider />
            <Aboutus/>
            <ServerList/>
            <Discord/>
            <Donate/>
            <Subscribe />
        </div>
    );
}

export default App;
