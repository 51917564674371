import React from 'react';
import feature from '../../images/features.jpg';
import './Aboutus.css';

function Aboutus() {
    return (
        <div id='features'>
            <div className="s-heading">
                <h1>About FrozenWave</h1>
            </div>
            <div id='features-body'>
                <div className='features-model'>
                    <img src={feature} alt='feature'/>
                </div>
                <div className='features-text'>
                    <h3>CSGO is life, Life is CSGO. (Same for CS2 as well)</h3>
                    <p><span className="highlight">Passionate</span> for Counter-Strike for over <span
                        className="highlight">7 years</span> now, we've decided to create FrozenWave to express how much
                        this means a lot to us by sharing this project with others who are just as passionated, but not
                        only, it's also for those who are nostalgic about Counter-Strike Source.</p>
                    <p>We are offering several servers on the community such as <span className="highlight"> CS2 and CS:GO surf servers</span>.</p>
                    <p>We've decided to do this project to continue the support the community's life and share our
                        passion. </p>
                    <p>There will be <span className="highlight">many more features</span> to come in the near future
                        which will be posted in the news section as it goes. This project is <span
                            className="highlight">totally free and simple to use.</span></p>
                    <p>We encourage everyone to come to our discord, either, for help, chat, play. It's
                        completely free and accessible for everyone so, don't be shy and come say hi!</p>
                    <p className="highlight">Have Fun!</p>
                </div>
            </div>
        </div>
    )
}

export default Aboutus;
